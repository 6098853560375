import { MapOptions } from 'components/common/MapComponent/MapComponentTypes';
import { useMapProperties } from 'components/common/MapComponent/MapPropertiesContext';
import { activeDirectionsAtom } from 'components/common/MapComponent/recoil/mapSettingsAtom';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { useMapControllerContext } from 'components/RoutePlanner/RouteBuilder/MapControllerContext';
import { TruckRestrictionsMapButton } from 'components/TruckRestrictions/TruckRestrictionsMapButton';
import { useTruckRestrictions } from 'hooks/useTruckRestrictions';
import { makeStyles } from 'lib/makeStyles';
import { MapControllers } from 'models/MapControllers';
import dynamic from 'next/dynamic';
import React, { useRef } from 'react';
import { useClickAway } from 'react-use';
import { useRecoilValue } from 'recoil';
import { truckMapConfig } from 'truckMapConfig';

const hasTenstreetButton = truckMapConfig.isTenstreet;
const isStaging = truckMapConfig.isStaging;

const MapRecenter = dynamic(() =>
  import('./MapRecenterOption').then((mod) => mod.MapRecenterOption)
);

const MapFeatures = dynamic(() =>
  import('./MapFeaturesOption').then((mod) => mod.MapFeaturesOption)
);

const RoutePlanner = dynamic(() =>
  import('./RoutePlannerOption').then((mod) => mod.RoutePlannerOption)
);

const PlacesSearch = dynamic(() =>
  import('./PlacesSearchOption').then((mod) => mod.PlacesSearchOption)
);

const DispatchPlanner = dynamic(() =>
  import('./DispatchPlannerOption').then((mod) => mod.DispatchPlannerOption)
);

const TrackingRefreshButton = dynamic(() =>
  import('./TrackingOption').then((mod) => mod.TrackingOptionRefreshButton)
);

const TrackingOptionCard = dynamic(() =>
  import('./TrackingOption').then((mod) => mod.TrackingOption)
);

const useStyles = makeStyles<{
  activeDirections: boolean;
  isDispatch?: boolean;
  isTracking?: boolean;
  isMapPage: boolean;
  isPlaceSearch: boolean;
}>()({
  root: ({
    activeDirections = false,
    isDispatch = false,
    isTracking = false,
    isMapPage = false,
    isPlaceSearch = false
  }) => [
    {
      'absolute z-map right-0 flex justify-end': isMapPage,
      'absolute top-0 right-0': isPlaceSearch,
      'bottom-0': (!activeDirections && !hasTenstreetButton) || isDispatch || isTracking,
      'bottom-12 md:bottom-0':
        !activeDirections && hasTenstreetButton && !isDispatch && !isTracking,
      'mb-10': activeDirections && hasTenstreetButton,
      'bottom-[102px]': activeDirections
    }
  ]
});

export const MapComponentOptions: React.FC = React.memo(() => {
  const mapProps = useMapProperties();
  const controllerId = useMapControllerContext();
  const { options } = mapProps;
  const { toggleMapSettings, show: truckRestrictionsVisible } = useTruckRestrictions(controllerId);
  const activeDirections = useRecoilValue(activeDirectionsAtom(controllerId));
  const containerRef = useRef();

  useClickAway(containerRef, () => {
    if (!truckRestrictionsVisible) return;
    toggleMapSettings(true);
  });

  const styles = useStyles({
    isMapPage: controllerId !== MapControllers.PLACE_SEARCH,
    activeDirections: Boolean(activeDirections),
    isDispatch: controllerId === MapControllers.DISPATCH,
    isTracking: controllerId === MapControllers.TRACKING_PAGE,
    isPlaceSearch: controllerId === MapControllers.PLACE_SEARCH
  });

  return (
    <>
      <div ref={containerRef} className={styles.root}>
        <PanelFlex paddingX="SM" paddingY="SM" align="end" spacing="MD">
          {options.includes(MapOptions.MAP_FEATURES) && <MapFeatures />}
          <PanelFlex column align="end" spacing="MD">
            {options.includes(MapOptions.MAP_RECENTER) && <MapRecenter />}
            {options.includes(MapOptions.MAP_FEATURES) && <TruckRestrictionsMapButton />}
            {options.includes(MapOptions.TRACKING) && isStaging && <TrackingRefreshButton />}
          </PanelFlex>
        </PanelFlex>
      </div>
      {options.includes(MapOptions.TRACKING) && <TrackingOptionCard />}
      {options.includes(MapOptions.ROUTE_PLANNER) && <RoutePlanner />}
      {options.includes(MapOptions.PLACES_SEARCH) && <PlacesSearch />}
      {options.includes(MapOptions.DISPATCH_PLANNER) && <DispatchPlanner />}
    </>
  );
});

MapComponentOptions.displayName = 'MapComponentOptions';
