import {
  buildFeature,
  colors,
  RoadAttrFeature,
  RoadAttrFeatureProperties,
  RoadAttrTile
} from '@truckmap/common';

import {
  Feature,
  featureCollection,
  FeatureCollection,
  LineString,
  MultiLineString,
  Point,
  Position
} from '@turf/helpers';
import { layerProperties } from 'lib/map/featureTiles/constants';
import { RoadAttrImageKey } from 'lib/map/featureTiles/types';
import { turfLength } from 'lib/turf';
import { LayerCollectionProperties } from 'types/map';
import { featureTypeToLayerNameDict } from 'types/roadAttributes';

export type RoadAttributeCollectionProperties = LayerCollectionProperties &
  RoadAttrFeatureProperties & {
    feature_length: number;
    accent_color_dark: string;
    accent_color_mid: string;
    accent_color_light: string;
    is_visible: boolean;
  };

export type RoadAttributeLineCollectionProperties = RoadAttributeCollectionProperties & {
  line_opacity: number;
};

export type RoadAttributePointCollectionProperties = RoadAttributeCollectionProperties & {
  icon_text?: string;
  icon_text_abbrev?: string;
  icon_text_color?: string;
  icon_text_size_sm?: number;
  icon_text_size_lg?: number;
  icon_size_sm?: number;
  icon_size_md?: number;
  icon_size_lg?: number;
  line_opacity: number;
};

const isFeatureInvisible = (feature: RoadAttrFeature, layers: string[] = []) => {
  const type = feature.properties.type;
  const layerNameForType = featureTypeToLayerNameDict[type];
  if (!layerNameForType) {
    return true;
  }

  const isInvisible = layers.includes(layerNameForType);
  if (isInvisible) {
    return true;
  }

  return false;
};

const isWeightProhibited = (
  roadMetricTons: number,
  currentUserOptions: {
    equipmentWeights: number;
    equipmentHeights: number;
  }
): boolean => {
  if (!currentUserOptions?.equipmentWeights) {
    return false;
  }

  return currentUserOptions.equipmentWeights > roadMetricTons;
};

const isHeightProhibited = (
  roadMeters: number,
  currentUserOptions: {
    equipmentWeights: number;
    equipmentHeights: number;
  }
): boolean => {
  if (!currentUserOptions?.equipmentHeights) {
    return false;
  }

  return currentUserOptions.equipmentHeights > roadMeters;
};

const lineAndIconFeatureProperties = (
  feature: Feature<RoadAttrFeature, RoadAttrFeatureProperties>,
  currentUserOptions?: {
    equipmentWeights: number;
    equipmentHeights: number;
  }
): Partial<RoadAttributePointCollectionProperties> => {
  let isProhibited = false;
  let accentColorDark: string | undefined;
  let accentColorMid: string | undefined;
  let accentColorLight: string | undefined;
  let iconTextSizeSm = 0;
  let iconTextSizeLg = 0;
  let iconText: string | undefined;
  let iconTextAbbrev: string | undefined;
  let iconTextColor: string | undefined;
  const iconSizeSm = 8;
  let iconSizeMd = 0;
  let iconSizeLg = 0;

  switch (feature.properties.type) {
    case 'truck-no':
      isProhibited = true;
      accentColorDark = '#801618';
      accentColorMid = '#9a1a1d';
      accentColorLight = '#cd2226';
      iconSizeMd = 16;
      iconSizeLg = 26;
      break;
    case 'maxweight':
      isProhibited = isWeightProhibited(feature.properties.value, currentUserOptions);
      accentColorDark = '#941f4f';
      accentColorMid = '#af255e';
      accentColorLight = '#dc2e77';
      iconText = feature.properties.imperial_display.toUpperCase();
      iconTextAbbrev = 'T';
      iconTextSizeSm = 8;
      iconTextSizeLg = 10;
      iconTextColor = 'white';
      iconSizeMd = 16;
      iconSizeLg = 26;
      break;
    case 'maxheight':
      isProhibited = isHeightProhibited(feature.properties.value, currentUserOptions);
      accentColorDark = '#847004';
      accentColorMid = '#978004';
      accentColorLight = '#dbba06';
      iconText = feature.properties.imperial_display.replace("'", ' ').replace('"', '');
      iconTextAbbrev = 'H';
      iconTextSizeSm = 8;
      iconTextSizeLg = 10;
      iconTextColor = '#584c0c';
      iconSizeMd = 20;
      iconSizeLg = feature.properties.imperial_display.length > 5 ? 46 : 38; // Increase the icon size for long strings like 13'10".
      break;
    case 'truck-designated':
    case 'truck-yes':
      accentColorDark = colors.indigo700;
      accentColorMid = colors.indigo500;
      accentColorLight = colors.indigo200;
      break;
    case 'construction':
      accentColorDark = colors.orange700;
      accentColorMid = colors.orange500;
      accentColorLight = colors.orange200;
      iconSizeMd = 16;
      iconSizeLg = 26;
      break;

    default:
      accentColorMid = colors.error400;
  }

  return {
    is_prohibited: isProhibited,
    accent_color_dark: accentColorDark,
    accent_color_mid: accentColorMid,
    accent_color_light: accentColorLight,
    image_key: RoadAttrImageKey[feature.properties.type],
    icon_text_size_sm: iconTextSizeSm,
    icon_text_size_lg: iconTextSizeLg,
    icon_text: iconText,
    icon_text_abbrev: iconTextAbbrev,
    icon_text_color: iconTextColor,
    icon_size_sm: iconSizeSm,
    icon_size_md: iconSizeMd,
    icon_size_lg: iconSizeLg,
    line_opacity:
      layerProperties.opacity[feature.properties.type] ?? layerProperties.opacity.default
  };
};

const getRestrictedRoadLines = (
  tilesDict: Record<number, RoadAttrTile>
): Feature<LineString | MultiLineString, RoadAttrFeatureProperties>[] => {
  const list: Feature<LineString | MultiLineString, RoadAttrFeatureProperties>[] = [];
  const allFeatures: RoadAttrFeature[] = [];
  for (const tile of Object.values(tilesDict)) {
    allFeatures.push(...tile.featureCollection.features);
  }

  for (const feature of allFeatures) {
    if (feature.geometry.type === 'LineString') {
      list.push(feature as Feature<LineString, RoadAttrFeatureProperties>);
    } else if (feature.geometry.type === 'MultiLineString') {
      list.push(feature as Feature<MultiLineString, RoadAttrFeatureProperties>);
    }
  }
  return list;
};

const getEndPointsFromLine = (
  line: Position[],
  properties: RoadAttrFeatureProperties & { feature_length: number }
): [Feature<Point, RoadAttrFeatureProperties>, Feature<Point, RoadAttrFeatureProperties>] => {
  const start = line[0];
  const end = line[line.length - 1];
  const startFeature: Feature<Point, RoadAttrFeatureProperties> = {
    ...buildFeature({ coordinates: start }),
    properties
  };
  const endFeature: Feature<Point, RoadAttrFeatureProperties> = {
    ...buildFeature({ coordinates: end }),
    properties
  };
  return [startFeature, endFeature];
};

const getRestrictedRoadEndPoints = (
  tilesDict: Record<number, RoadAttrTile>
): Feature<Point, RoadAttrFeatureProperties>[] => {
  let list: Feature<Point, RoadAttrFeatureProperties>[] = [];
  const allFeatures: RoadAttrFeature[] = [];
  for (const tile of Object.values(tilesDict)) {
    allFeatures.push(...tile.featureCollection.features);
  }

  for (const feature of allFeatures) {
    if (feature.geometry.type === 'LineString') {
      const line = feature.geometry.coordinates;
      const featureLength = Math.round(turfLength(feature));
      const endpointFeatures = getEndPointsFromLine(line, {
        ...feature.properties,
        feature_length: featureLength
      });
      list = [...list, ...endpointFeatures];
    } else if (feature.geometry.type === 'MultiLineString') {
      for (const line of feature.geometry.coordinates) {
        const featureLength = Math.round(turfLength(feature));
        const endpointFeatures = getEndPointsFromLine(line, {
          ...feature.properties,
          feature_length: featureLength
        });
        list = [...list, ...endpointFeatures];
      }
    }
  }
  return list;
};

export const roadAttributeLinesFeatureCollection = (
  tilesDict: Record<number, RoadAttrTile>,
  invisibleLayers: string[],
  currentUserOptions?: {
    equipmentWeights: number;
    equipmentHeights: number;
  }
): FeatureCollection<LineString | MultiLineString, RoadAttributeLineCollectionProperties> => {
  const _features: Feature<LineString | MultiLineString, RoadAttributeLineCollectionProperties>[] =
    [];

  for (const line of getRestrictedRoadLines(tilesDict)) {
    if (isFeatureInvisible(line, invisibleLayers)) {
      continue;
    }

    const _feature = {
      ...line,
      properties: {
        ...line.properties,
        ...lineAndIconFeatureProperties(
          line as unknown as Feature<RoadAttrFeature, RoadAttrFeatureProperties>,
          currentUserOptions
        )
      }
    };

    _features.push(
      _feature as Feature<LineString | MultiLineString, RoadAttributeLineCollectionProperties>
    );
  }

  return featureCollection(_features);
};

export const roadAttributePointsFeatureCollection = (
  tilesDict: Record<number, RoadAttrTile>,
  invisibleLayers: string[],
  currentUserOptions?: {
    equipmentWeights: number;
    equipmentHeights: number;
  }
): FeatureCollection<Point, RoadAttributePointCollectionProperties> => {
  const _features: Feature<Point, RoadAttributePointCollectionProperties>[] = [];

  for (const point of getRestrictedRoadEndPoints(tilesDict)) {
    if (isFeatureInvisible(point, invisibleLayers)) {
      continue;
    }
    const _feature = {
      ...point,
      properties: {
        ...point.properties,
        ...lineAndIconFeatureProperties(
          point as unknown as Feature<RoadAttrFeature, RoadAttrFeatureProperties>,
          currentUserOptions
        )
      }
    };
    _features.push(_feature as Feature<Point, RoadAttributePointCollectionProperties>);
  }

  return featureCollection(_features);
};
